import { createClient } from "next-sanity";
import createImageUrlBuilder from '@sanity/image-url';

export const sanityClient = createClient({
  projectId: "10gj8xqe",
  dataset: "production",
  apiVersion: "2023-01-16",
  useCdn: true
});

export function urlFor(source) {
  return createImageUrlBuilder(sanityClient).image(source).auto('format');
};