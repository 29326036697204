//https://codesandbox.io/s/silent-butterfly-20iujh?file=/src/App.js

import { useContext, useState, useRef, useEffect } from 'react';
import { useRouter } from 'next/router';
import {
  Hits,
  Configure,
  SearchBox,
  useInstantSearch,
} from 'react-instantsearch-hooks-web';
import useOnClickOutside from "../../hooks/useOnClickOutside";

import StoreContext from '../../context/store';
import styles from './Searchbar.module.scss'
import { faArrowRight, faMessageXmark, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useDisableBodyScroll from '../../hooks/useDisableBodyScroll';
import useMediaQuery from '../../hooks/useMediaQuery';

import ProductCard from '../../components/product/ProductCard';
import Link from 'next/link';
import { AlgoliaSearchProvider } from '../../context/algolia';


export default function Searchbar(props) {

  const { store, modal } = useContext(StoreContext);
  const [showResults, setShowResults] = useState(false);
  useDisableBodyScroll(showResults)
  const router = useRouter();
  const isBreakPoint = useMediaQuery(768)

  // hide on route change
  useEffect(() => {
    const handleRouteChange = () => {
      setShowResults(false);
    };
    // listen
    router.events.on('routeChangeStart', handleRouteChange);
    // clean
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events]);

  // close on click outside
  const ref = useRef();
  const dummyRef = useRef();
  useOnClickOutside(ref, () => setShowResults(false));

  // hide if locaton modal and reults are open
  useEffect(() => {
    if (modal) {
      setShowResults(false);
      if (showResults && props.handleClose) {
        props.handleClose();
      }
    }
  }, [modal, props.handleClick]);


  return (
    <AlgoliaSearchProvider>
      <div className={styles.search}>

        <div className={styles.searchBox} ref={!isBreakPoint ? ref : dummyRef}>
          <div className={styles.close}>
            <button className="button" data-type="close" aria-label="close search" onClick={() => props.handleClick()}>
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>

          <Configure filters={`store_id:${store}`} hitsPerPage="12" />
          <div className={styles.searchBox__content} onClick={() => setShowResults(true)}>
            <SearchBox placeholder={'Search for products...'} />
          </div>

          {/* <EmptyQueryBoundary fallback={"null"} showResults={showResults} setShowResults={setShowResults}> */}
          {showResults && (
            <div className={styles.searchResults} >
              <div className={styles.content}>
                {/* <div className={styles.suggestions}>
                  <h2>Top Suggestions</h2>
                  <p>Suggested searches...</p>
                </div> */}
                <NoResultsBoundary fallback={<NoResults />}>
                  <div className={styles.results}>
                    <div className={styles.results__title}>
                      <h2>Seach Results</h2>
                      <AllResultsLink setShowResults={setShowResults} />
                    </div>
                    <Hits hitComponent={ProductCard} />

                  </div>

                </NoResultsBoundary>
              </div>
            </div>


          )}
          {/* </EmptyQueryBoundary> */}

        </div>
        {showResults && <div className={styles.backdrop} onClick={() => setShowResults(false)}></div>}
      </div>
    </AlgoliaSearchProvider>
  );
}


function NoResultsBoundary({ children, fallback }) {
  const { results } = useInstantSearch();

  // The `__isArtificial` flag makes sure not to display the No Results message
  // when no hits have been returned yet.
  if (!results.__isArtificial && results.nbHits === 0) {
    return (
      <>
        {fallback}
        <div hidden>{children}</div>
      </>
    );
  }

  return children;
}

function NoResults() {
  const { indexUiState } = useInstantSearch();

  return (
    <div className={styles.no_results}>
      <FontAwesomeIcon className="icon fa-4x" icon={faMessageXmark} />
      <span>
        {indexUiState.query ?
          <span>No products match your search: <q>{indexUiState.query}</q></span>
          : "No products match your search criteria or are available at this location."}
      </span>
      {/* <CustomClearRefinements text="Clear all filters" includedAttributes={['query', 'category', 'brand', 'root_types', 'feelings', 'available_weights', 'applicable_special_ids']} /> */}
    </div>
  );
}

function EmptyQueryBoundary({ children, fallback, showResults, setShowResults }) {
  const { indexUiState } = useInstantSearch();

  if (!indexUiState.query) {
    // setShowResults(false);
    return fallback;
  }
  // else {
  //   setShowResults(true);
  // }
  return children;
}

function AllResultsLink({ setShowResults }) {
  const { indexUiState } = useInstantSearch();
  return (
    <Link
      href={`${indexUiState.query ? `/shop?q=${indexUiState.query}` : `/shop`}`}
      onClick={() => setShowResults(false)}
      className="button"
      data-type="primary"
    >
      View All Results
      <FontAwesomeIcon className="fs-300 pl-1 chevronArrow" icon={faArrowRight} />
    </Link>
  )
}


