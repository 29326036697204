import { useContext, useRef } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Index, useHits, Configure } from 'react-instantsearch-hooks-web';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, FreeMode, Scrollbar } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import 'swiper/css/free-mode';

import ProductCard from '../../components/product/ProductCard';
import StoreContext from '../../context/store';
import Button from '../../components/Button';
import SkeletonElement from '../../components/skeletons/SkeletonElement';
import Sale from '../../public/icons/sale.svg';
import Flower from '../../public/icons/flower.svg';
import Preroll from '../../public/icons/pre-roll.svg';
import Extract from '../../public/icons/extract.svg';
import Vape from '../../public/icons/vape.svg';
import Edible from '../../public/icons/edible.svg';
import Tincture from '../../public/icons/tincture.svg';
import Topical from '../../public/icons/topical.svg';
import Gear from '../../public/icons/gear.svg';
import Merch from '../../public/icons/merch.svg';

import styles from './ProductSlider.module.scss';

export default function ProductSlider({ title, subtitle, icon, filters }) {
  const { store } = useContext(StoreContext);

  // Parse filters into a structured object
  const parseFilters = (filters) => {
    return filters
      ?.split('AND')
      .map((condition) => condition.trim())
      .reduce((acc, condition) => {
        const [key, value] = condition.split(':').map((s) => s.trim().replace(/"/g, ''));
        acc[key] = value;
        return acc;
      }, {});
  };

  const parsedFilters = parseFilters(filters);
  const { root_types, strain, brand, special } = parsedFilters;

  return (
    <Index indexName="menu-products-by-rating-production">
      <Configure hitsPerPage={11} filters={`store_id:${store} AND ${filters}`} />
      <HitsSwiper
        title={title}
        subtitle={subtitle}
        icon={icon}
        filters={filters}
        root_types={root_types}
        strain={strain}
        brand={brand}
        special={special}
      />
    </Index>
  );
}

const categoryIcons = {
  sale: <Sale />,
  flower: <Flower />,
  'pre-roll': <Preroll />,
  extract: <Extract />,
  vape: <Vape />,
  edible: <Edible />,
  tincture: <Tincture />,
  topical: <Topical />,
  gear: <Gear />,
  merch: <Merch />,
};

function HitsSwiper({ title, subtitle, root_types, strain, brand, special, icon }) {
  const { hits, loading, error } = useHits();
  const swiperRef = useRef(null);
  const swiperEleId = title?.replace(/[^\w]+/g, '');
  const router = useRouter();

  const getHref = () => {
    if (brand) return `/brands/${brand.replace(/ /g, '-')}`;
    if (strain) return `/shop?strain=${strain}`;
    if (root_types) return `/shop/${root_types}`;
    if (special) return `/shop?special=${special}`;
    return '/shop';
  };

  const href = getHref();

  const swiperConfig = {
    modules: [Navigation, FreeMode, Scrollbar],
    className: styles.productSwiper,
    spaceBetween: 16,
    touchEventsTarget: 'container',
    breakpoints: {
      1024: { slidesPerView: 4, slidesPerGroup: 4, spaceBetween: 16 },
      768: { slidesPerView: 3, slidesPerGroup: 3, spaceBetween: 14 },
      370: { slidesPerView: 2, slidesPerGroup: 1, spaceBetween: 10 },
      0: { slidesPerView: 1.2, slidesPerGroup: 1, spaceBetween: 10 },
    },
    navigation: {
      prevEl: `#${swiperEleId}-prev`,
      nextEl: `#${swiperEleId}-next`,
      clickable: true,
    },
    scrollbar: { hide: true },
    onSwiper: (swiper) => (swiperRef.current = swiper),
  };

  if (error) return <div>Error: {error.message}</div>;

  return (
    <>
      {loading ? (
        <div>
          <SkeletonElement type="title-large" className="ml-100" style={{ height: '150px' }} />
          <div className="reel">
            {Array(4)
              .fill()
              .map((_, idx) => (
                <SkeletonElement key={idx} type="product-card" />
              ))}
          </div>
        </div>
      ) : hits.length > 0 ? (
        <div className={styles.slider}>
          <div className={styles.header}>
            {/* Icon and Title */}
            <div className={styles.header__content}>
              <div className={styles.title}>
                {icon && <div className={styles.icon}>{categoryIcons[root_types]}</div>}
                <div>
                  <h2>{title}</h2>
                  {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
                </div>
              </div>
            </div>
            {/* Stats and View More */}
            <Stats href={href} />
          </div>

          <div className={`${styles.container} product-slider`}>
            <Swiper {...swiperConfig}>
              <div className={styles.prev} id={`${swiperEleId}-prev`}>
                <Button type="button" className="slider-arrow" ariaLabel="Previous Slide">
                  <FontAwesomeIcon icon={faArrowLeft} />
                </Button>
              </div>
              <div className={styles.next} id={`${swiperEleId}-next`}>
                <Button type="button" className="slider-arrow" ariaLabel="Next Slide">
                  <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </div>
              {hits.map((hit) =>
                router.query.id !== hit.product_id ? (
                  <SwiperSlide key={hit.product_id}>
                    <ProductCard hit={hit} />
                  </SwiperSlide>
                ) : null
              )}
              <SwiperSlide className={styles.lastSlide}>
                <Link href={href}>
                  {icon && <div className={styles.icon}>{categoryIcons[root_types]}</div>}
                  View All
                </Link>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      ) : null}
    </>
  );

}

function Stats({ href }) {
  const { results } = useHits();

  return (
    <Button type="link" href={href} className="sliderLink" prefetch={false} ariaLabel="View All">
      View All ({results.nbHits})
    </Button>
  );
}