import { createContext, useState, useEffect, useRef, useCallback } from "react";
import Cookies from "js-cookie";

const StoreContext = createContext({
  store: null,
  storeName: null,
  orderType: null,
  zipcode: null,
  address: null,
  modal: null,
  stores: null,
  pickupStores: null,
  deliveryZips: null,
  setStore: null,
  setStoreName: null,
  setOrderType: null,
  setZipcode: null,
  setAddress: null,
  setModal: null,
  fetchStores: null,
  fetchPickupStores: null,
  fetchDeliveryZips: null,
});

export const StoreProvider = ({ children }) => {
  const [store, setStore] = useState(null);
  const [storeName, setStoreName] = useState(null);
  const [orderType, setOrderType] = useState(null);
  const [zipcode, setZipcode] = useState(null);
  const [modal, setModal] = useState(false);
  const [address, setAddress] = useState({});
  const [stores, setStores] = useState(null);
  const [pickupStores, setPickupStores] = useState(null);
  const [deliveryZips, setDeliveryZips] = useState(null);

  const cache = useRef(new Map());
  const pendingRequests = useRef(new Map());

  // Initialize state from cookies or defaults
  useEffect(() => {
    const cookieStore = Cookies.get("JANE_STORE");
    const cookieStoreName = Cookies.get("STORE_NAME");
    const cookieOrderType = Cookies.get("ORDER_TYPE");
    const cookieZipcode = Cookies.get("ZIP_CODE");

    setStore(cookieStore ? parseInt(cookieStore) : 4398);
    setStoreName(cookieStoreName || "San Jose");
    setOrderType(cookieOrderType || "Browsing");
    setZipcode(cookieZipcode || "");
  }, []);

  // Sync state with cookies only when necessary
  useEffect(() => {
    if (store !== null) {
      Cookies.set("JANE_STORE", store, { expires: 365 });
    }
    if (storeName) {
      Cookies.set("STORE_NAME", storeName, { expires: 365 });
    }
    if (orderType) {
      Cookies.set("ORDER_TYPE", orderType, { expires: 365 });
    }
    if (zipcode) {
      Cookies.set("ZIP_CODE", zipcode, { expires: 365 });
    }
    if (address && typeof window !== "undefined") {
      const addressString = JSON.stringify(address);
      localStorage.setItem("userAddress", addressString);
    }
    // console.log("sync cookies", store, storeName, orderType, zipcode, address);
  }, [store, storeName, orderType, zipcode, address]);

  // fetch all stores
  const fetchStores = useCallback(async () => {
    // Check if the stores are already cached
    if (cache.current.has("stores")) {
      setStores(cache.current.get("stores"));
      return;
    }

    // Check if a fetch request is already pending
    if (pendingRequests.current.has("stores")) {
      return pendingRequests.current.get("stores");
    }

    // Create a fetch promise and store it in pendingRequests
    const fetchPromise = (async () => {
      try {
        const response = await fetch("/api/jane-stores");
        if (!response.ok) {
          throw new Error("Failed to fetch stores");
        }
        const { data } = await response.json();

        // Cache the fetched stores and update the state
        cache.current.set("stores", data.stores);
        setStores(data.stores);
        return data.stores;
      } catch (error) {
        console.error("Error fetching stores:", error);
        setStores([]); // Fallback to an empty array in case of error
        return null;
      } finally {
        pendingRequests.current.delete("stores");
      }
    })();

    pendingRequests.current.set("stores", fetchPromise);
    return fetchPromise;
  }, []);

  const fetchStoreDetails = useCallback(
    async (storeId) => {
      if (cache.current.has(storeId)) {
        return cache.current.get(storeId);
      }

      if (pendingRequests.current.has(storeId)) {
        return pendingRequests.current.get(storeId);
      }

      const fetchPromise = (async () => {
        try {
          const response = await fetch(`/api/jane-store/${storeId}`);
          if (!response.ok) {
            console.error(`Failed to fetch details for store ID ${storeId}`);
            return null;
          }
          const { data } = await response.json();
          cache.current.set(storeId, data.store);
          return data.store;
        } catch (error) {
          console.error(`Error fetching store details for ID ${storeId}:`, error);
          return null;
        } finally {
          pendingRequests.current.delete(storeId);
        }
      })();

      pendingRequests.current.set(storeId, fetchPromise);
      return fetchPromise;
    },
    []
  );

  // fetch all stores that have pickup: true, remove stores with ID 117 and 2100 (117 is used for delivery but has pickup enabled, 2100 is sandbox)
  const fetchPickupStores = useCallback(async () => {
    if (!stores) {
      console.warn("Stores not loaded. Fetch stores first.");
      return;
    }

    try {
      const pickupStoresData = stores
        .filter((store) => store.pickup === true)
        .filter((store) => store.id !== 117 && store.id !== 2100)

      const detailedPickupStores = await Promise.all(
        pickupStoresData.map((store) => fetchStoreDetails(store.id))
      );

      setPickupStores(detailedPickupStores.filter(Boolean));
    } catch (error) {
      console.error("Error fetching pickup stores:", error);
      setPickupStores([]);
    }
  }, [stores, fetchStoreDetails]);

  // fetch and flatten all delivery_zip objects from stores with delivery: true
  const fetchDeliveryZips = useCallback(async () => {
    if (!stores) {
      console.warn("Stores not loaded. Fetch stores first.");
      return;
    }

    try {
      const deliveryStores = stores.filter((store) => store.delivery === true);

      const deliveryZipsArray = await Promise.all(
        deliveryStores.map(async (store) => {
          const storeDetails = await fetchStoreDetails(store.id);
          return storeDetails?.delivery_zipcodes || []; // Return the full delivery_zipcode object
        })
      );

      const allDeliveryZips = deliveryZipsArray.flat(); // Flatten the array
      setDeliveryZips(allDeliveryZips); // Update the state with full objects
    } catch (error) {
      console.error("Error fetching delivery zips:", error);
      setDeliveryZips([]);
    }
  }, [stores, fetchStoreDetails]);


  const value = {
    store,
    storeName,
    orderType,
    zipcode,
    address,
    modal,
    stores,
    pickupStores,
    deliveryZips,
    setStore,
    setStoreName,
    setOrderType,
    setZipcode,
    setAddress,
    setModal,
    fetchStores,
    fetchPickupStores,
    fetchDeliveryZips,
  };

  return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>;
};

export default StoreContext;
