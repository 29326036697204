import { useEffect, useContext } from "react";
import Head from "next/head";
import { NextSeo } from "next-seo";

import StoreContext from "../context/store";
import { AlgoliaSearchProvider } from "../context/algolia";
import { useSpecials } from "../context/specials";

import Hero from "../components/hero";
import Specials from "../templates/specials/Specials";
import BlogSlider from "../templates/sliders/BlogSlider";
import ProductSlider from "../templates/sliders/ProductSlider";
import BrandSlider from "../templates/sliders/BrandSlider";
import CategorySlider from "../templates/sliders/CategorySlider";
import WhyPurpleLotus from "../templates/sliders/WhyPurpleLotus";

export async function getServerSideProps() {
  const { sanityClient } = await import("../lib/Sanity");
  const posts = await sanityClient.fetch(
    `*[_type == "post"] | order(_createdAt desc) [0..6] {
      _id,
      title,
      slug,
      createdAt,
      categories[] -> { title },
      mainImage
    }`
  );
  return {
    props: {
      posts,
    },
  };
}

export default function HomePage({ posts }) {
  const { store } = useContext(StoreContext);
  const { specials, fetchAllSpecials, loading, error } = useSpecials();

  // Fetch Jane specials
  useEffect(() => {
    if (store) {
      fetchAllSpecials(store);
    }
  }, [store, fetchAllSpecials]);

  return (
    <>
      <header>
        <Head>
          <link rel="preconnect" href="https://cdn.sanity.io" />
          <link rel="dns-prefetch" href="https://cdn.sanity.io" />
        </Head>
        <NextSeo
          title="Shop Cannabis Delivery and In-Store Pickup | Purple Lotus"
          description="Are you looking for a dispensary in San Jose, CA? Call (408)456-0420 or visit Purple Lotus for a comfortable atmosphere, and top-quality cannabis for every lifestyle."
          canonical="https://plpcsanjose.com"
          openGraph={{
            url: "https://plpcsanjose.com",
            title: "Shop Cannabis Delivery and In-Store Pickup | Purple Lotus",
            description:
              "Are you looking for a dispensary in San Jose, CA? Call (408)456-0420 or visit Purple Lotus for a comfortable atmosphere, and top-quality cannabis for every lifestyle.",
            images: [
              {
                url: "https://plpcsanjose.com/images/purple-lotus-dispensary-building.jpg",
                width: 800,
                height: 600,
                alt: "Purple Lotus Cannabis Dispensary",
                type: "image/jpeg",
              },
            ],
            siteName: "Purple Lotus",
          }}
        />

        <Hero />
      </header>
      <main>
        <section className="container-row flex-column gap-300">
          <Specials
            title="Deals"
            specials={specials}
          />
          {/* <ProductSlider title="Sale" filters="root_types:sale" icon /> */}
          <BrandSlider />
          <CategorySlider title="Categories" />
          <AlgoliaSearchProvider>
            <ProductSlider
              title="Flower"
              filters={`root_types:"flower"`}
              icon
            />
            <ProductSlider
              title="Pre-roll"
              filters={`root_types:"pre-roll"`}
              icon
            />
            <ProductSlider
              title="Extract"
              filters={`root_types:"extract"`}
              icon
            />
            <ProductSlider title="Vape" filters={`root_types:"vape"`} icon />
            <ProductSlider
              title="Edible"
              filters={`root_types:"edible"`}
              icon
            />
            <ProductSlider
              title="Tincture"
              filters={`root_types:"tincture"`}
              icon
            />
            <ProductSlider
              title="Topical"
              filters={`root_types:"topical"`}
              icon
            />
            <ProductSlider title="Gear" filters={`root_types:"gear"`} icon />
            <ProductSlider title="Merch" filters={`root_types:"merch"`} icon />

            {/* <ProductSlider title="Blue Chip" filters={`brand:"blue chip"`} /> */}
          </AlgoliaSearchProvider>
        </section>
        <section className="row pb-3 flex-column gap-400" id="why">
          <WhyPurpleLotus />
          <BlogSlider posts={posts} />
        </section>
      </main>
    </>
  );
}
