import { useState, useEffect } from "react";
import { sanityClient, urlFor } from "../../lib/Sanity";
import styles from "./Hero.module.scss";
import Skeleton from "../skeletons/SkeletonHero";
import Image from "next/image";
import UseMediaQuery from "../../hooks/useMediaQuery";
import Head from "next/head";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/pro-regular-svg-icons";

import { Swiper, SwiperSlide } from "swiper/react";
import Button from "../Button";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper";

const fetchData = async () => {
  const query = `*[_type == "homepageHeader" && active == true] | order(_updatedAt desc)  {
      _id,
      title,
      body,
      buttonText,
      buttonUrl,
      backgroundImage,
      mobileBackgroundImage
    }`;
  return sanityClient.fetch(query);
};

export default function HomepageHero() {
  const isBreakPoint = UseMediaQuery(768);
  const [swiperRef, setSwiperRef] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await fetchData();
        setData(result);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);

  if (loading) return <Skeleton />;
  if (error) return <div>Failed to load header</div>;

  // Preload the first image
  const firstImageUrl = isBreakPoint
    ? urlFor(data[0].mobileBackgroundImage || data[0].backgroundImage)
        .width(600)
        .quality(100)
        .auto("format")
        .url()
    : urlFor(data[0].backgroundImage)
        .width(1600)
        .quality(100)
        .auto("format")
        .url();

  return (
    <>
      <Head>
        <link
          rel="preload"
          as="image"
          href={firstImageUrl}
          imagesrcset={`${firstImageUrl} 600w, ${firstImageUrl.replace("600", "1600")} 1600w`}
          imagesizes="(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 1600px"
        />
      </Head>
      <div className={styles.hero}>
        <div id="hero-prev" className={styles.prev}>
          <FontAwesomeIcon icon={faArrowLeft} aria-label="previous slide" />
        </div>
        <Swiper
          onSwiper={(swiper) => {
            setSwiperRef(swiper);
            swiper.update();
          }}
          spaceBetween={0}
          slidesPerView={1}
          modules={[Pagination, Navigation]}
          keyboard={{
            enabled: true,
            onlyInViewport: false,
          }}
          pagination={{
            clickable: true,
            el: `#hero-pagination`,
          }}
          navigation={{
            prevEl: `#hero-prev`,
            nextEl: `#hero-next`,
            clickable: true,
          }}
          loop={true}
        >
          {data.map((slide, index) => {
            const slideImageUrl = isBreakPoint
              ? urlFor(slide.mobileBackgroundImage || slide.backgroundImage)
                  .width(600)
                  .quality(100)
                  .auto("format")
                  .url()
              : urlFor(slide.backgroundImage)
                  .width(1600)
                  .quality(100)
                  .auto("format")
                  .url();

            return (
              <SwiperSlide key={slide._id || index} className={styles.swiperSlide}>
                <div className={styles.overlay}>
                  <Image
                    alt={`Background image for slide ${index + 1}`}
                    src={slideImageUrl}
                    sizes="(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 1600px"
                    fill
                    priority={index === 0} // Only prioritize the first image
                    placeholder="blur"
                    blurDataURL="data:image/jpeg;base64,[small_base64_image]"
                    quality={100}
                  />
                  <div className={`${styles.hero__content__wrapper} container`}>
                    <div className={styles.hero__content}>
                      <h1 className={styles.hero__title}>{slide.title}</h1>
                      <p className={styles.hero__body}>{slide.body}</p>
                      {slide.buttonUrl && (
                        <Button
                          type="link"
                          href={slide.buttonUrl || "/deals"}
                          className="button hero"
                        >
                          {slide.buttonText}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
          <div id="hero-pagination" className={styles.bullets}></div>
        </Swiper>
        <div id="hero-next" className={styles.next}>
          <FontAwesomeIcon icon={faArrowRight} aria-label="next slide" />
        </div>
      </div>
    </>
  );
}
