import { InstantSearch, InstantSearchSSRProvider } from 'react-instantsearch-hooks-web';
import algoliasearch from 'algoliasearch/lite';

const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_KEY
);

const indexName = "menu-products-by-rating-production";

export const AlgoliaSearchProvider = ({ children, serverState }) => {
  return (
    <InstantSearchSSRProvider {...serverState}>
      <InstantSearch searchClient={searchClient} indexName={indexName}>
        {children}
      </InstantSearch>
    </InstantSearchSSRProvider>
  );
};
