import styles from './Brand.module.scss'
import Link from 'next/link';

export default function BrandLink({brand }) {
  return (
    <Link
      href={`/brands/${brand?.replace(/ /g, "-").replace(/&/g, "%26")}`}
      aria-label={brand || "brand"}
      attribute={brand}
      onClick={e => e.stopPropagation()}
      prefetch={false}
      className={styles.brand}
    >
      {brand}
    </Link>
  )
}