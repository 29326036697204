import Link from 'next/link';
import styles from './styles.module.scss';

export default function Button({ type, onClick, href, children, className, ariaLabel, id, alt, as }) {
  // Split the className string into an array and map to styles
  const additionalClasses = className ? className.split(' ').map(cn => styles[cn] || '').filter(cn => cn) : [];
  // Combine the base button class with additional classes
  const buttonClass = [styles.button, ...additionalClasses].join(' ').trim();

  if (type === "button") {
    return (
      <button role="button" onClick={onClick} className={buttonClass} aria-label={ariaLabel} tabIndex={0} id={id} alt={alt}>
        {children}
      </button>
    );
  }

  if (type === "link" && href) {
    return (
      <Link href={href} as={as} className={buttonClass} aria-label={ariaLabel} tabIndex={0} id={id} alt={alt} onClick={onClick}>
        {children}
      </Link>
    );
  }

  return "Button type and/or href not specified";
};