import styles from './Skeleton.module.scss'
import SkeletonElement from './SkeletonElement';
import Shimmer from './Shimmer'

export default function SkeletonCard() {
  return (
    <div className={styles.dealCard}>
      <div>
        <SkeletonElement type="thumbnail" />
        <div className={styles.content}>
          <SkeletonElement type="title" />
          <SkeletonElement type="text" />
        </div>
      </div>
      {/* <Shimmer /> */}
    </div>
  );
}
