import { useState, useEffect, useRef, useCallback } from "react";

export function LottieIcon({ animatedIcon, isHovered, animate }) {
  const [lottieInstance, setLottieInstance] = useState(null); 
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);

  // IntersectionObserver to detect visibility
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 } // Trigger when at least 10% of the element is visible
    );

    if (containerRef.current) observer.observe(containerRef.current);

    return () => observer.disconnect();
  }, []);

  // Load Lottie animation dynamically when visible
  useEffect(() => {
    if (isVisible && !lottieInstance) {
      import("lottie-web").then(({ default: Lottie }) => {
        const instance = Lottie.loadAnimation({
          container: containerRef.current, // Target the container div
          animationData: animatedIcon, // Pass the animation JSON file
          loop: false, 
          autoplay: false,
        });
        setLottieInstance(instance); // Store the Lottie instance
      });
    }
  }, [isVisible, animatedIcon, lottieInstance]);

  // Function to play animation between specific frames
  const playAnimation = useCallback(
    (startFrame, endFrame) => {
      if (lottieInstance) {
        lottieInstance.playSegments([startFrame, endFrame], true);
      }
    },
    [lottieInstance]
  );

  // Handle animation on hover
  useEffect(() => {
    if (isHovered) {
      playAnimation(0, animatedIcon.op);
    }
  }, [isHovered, playAnimation, animatedIcon.op]);

  // Handle animation on explicit trigger
  useEffect(() => {
    if (animate) {
      playAnimation(0, animatedIcon.op);
    }
  }, [animate, playAnimation, animatedIcon.op]);

  return <div ref={containerRef} style={{ width: "100%", height: "100%" }} />;
}
