import { createContext, useContext, useState, useCallback } from "react";
import { getJaneSpecials, getAllJaneSpecials } from "../lib/Jane";

const SpecialsContext = createContext(
  {
    specials: [],
    loading: false,
    error: null,
    fetchSpecialsByType: () => {},
    fetchAllSpecials: () => {},
  }
);

export const SpecialsProvider = ({ children }) => {
  const [specialsCache, setSpecialsCache] = useState({}); // Caching specials by storeId and type
  const [specials, setSpecials] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch a single type of specials
  const fetchSpecialsByType = useCallback(async (storeId, type) => {
    if (!storeId || !type) {
      console.warn("Store ID and type are required to fetch specials.");
      return;
    }

    const cacheKey = `${storeId}-${type}`;
    if (specialsCache[cacheKey]) {
      console.log(`Cache hit for specials of type ${type} in store ${storeId}`);
      setSpecials(specialsCache[cacheKey]);
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const fetchedSpecials = await getJaneSpecials(storeId, type);
      setSpecialsCache((prevCache) => ({
        ...prevCache,
        [cacheKey]: fetchedSpecials || [],
      })); // Update the cache
      setSpecials(fetchedSpecials || []);
    } catch (err) {
      console.error("Error fetching specials by type:", err);
      setError(err.message || "Failed to fetch specials");
      setSpecials([]);
    } finally {
      setLoading(false);
    }
  }, [specialsCache]);

  // Fetch all specials
  const fetchAllSpecials = useCallback(async (storeId) => {
    if (!storeId) {
      console.warn("Store ID is required to fetch all specials.");
      return;
    }

    const cacheKey = `${storeId}-all`;
    if (specialsCache[cacheKey]) {
      console.log(`Cache hit for all specials in store ${storeId}`);
      setSpecials(specialsCache[cacheKey]);
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const fetchedSpecials = await getAllJaneSpecials(storeId);
      setSpecialsCache((prevCache) => ({
        ...prevCache,
        [cacheKey]: fetchedSpecials || [],
      })); // Update the cache
      setSpecials(fetchedSpecials || []);
    } catch (err) {
      console.error("Error fetching all specials:", err);
      setError(err.message || "Failed to fetch all specials");
      setSpecials([]);
    } finally {
      setLoading(false);
    }
  }, [specialsCache]);

  return (
    <SpecialsContext.Provider
      value={{
        specials,
        loading,
        error,
        fetchSpecialsByType,
        fetchAllSpecials,
      }}
    >
      {children}
    </SpecialsContext.Provider>
  );
};

export const useSpecials = () => useContext(SpecialsContext);
