import Link from "next/link";
import dynamic from "next/dynamic";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/pro-regular-svg-icons";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import { Navigation, Pagination, FreeMode } from "swiper";

import styles from "./BrandSlider.module.scss";
import Button from "../../components/Button";

// Dynamically import SVGs
const BlueChip = dynamic(() => import("../../public/brand-logos/svg/blue-chip.svg"));
const Stiiizy = dynamic(() => import("../../public/brand-logos/svg/stiiizy.svg"));
const Jeeter = dynamic(() => import("../../public/brand-logos/svg/jeeter.svg"));
const HeavyHitters = dynamic(() => import("../../public/brand-logos/svg/heavy-hitters.svg"));
const Wyld = dynamic(() => import("../../public/brand-logos/svg/wyld.svg"));
const RawGarden = dynamic(() => import("../../public/brand-logos/svg/raw-garden.svg"));
const AlienLabs = dynamic(() => import("../../public/brand-logos/svg/alien-labs.svg"));
const Connected = dynamic(() => import("../../public/brand-logos/svg/connected.svg"));
const Papa = dynamic(() => import("../../public/brand-logos/svg/papa-and-barkley.svg"));
const Abx = dynamic(() => import("../../public/brand-logos/svg/abx.svg"));
const Brands = dynamic(() => import("../../public/icons/grid-solid.svg"));

export default function Slider() {
  const brands = [
    {
      name: "Blue Chip",
      logo: <BlueChip />,
      color: "#fff",
      backgroundColor: "#3067a6",
    },
    {
      name: "Jeeter",
      logo: <Jeeter />,
      color: "#FDC601",
      backgroundColor: "#702E8F",
    },
    {
      name: "Stiiizy",
      logo: <Stiiizy />,
      color: "#fff",
      backgroundColor: "#000",
    },
    {
      name: "AbsoluteXtracts",
      logo: <Abx />,
      color: "#000",
      backgroundColor: "#FEC52E",
    },
    {
      name: "Papa & Barkley",
      logo: <Papa />,
      color: "#fff",
      backgroundColor: "#5B7E96",
    },
    {
      name: "Connected Cannabis Co",
      logo: <Connected />,
      color: "#fff",
      backgroundColor: "#0198CD",
    },
    {
      name: "Alien+Labs",
      logo: <AlienLabs />,
      color: "#000",
      backgroundColor: "#79F104",
    },
    {
      name: "Wyld",
      logo: <Wyld />,
      color: "#000",
      backgroundColor: "#fff",
    },
    {
      name: "Heavy Hitters",
      logo: <HeavyHitters />,
      color: "#fff",
      backgroundColor: "#000",
    },
    {
      name: "Raw Garden™",
      logo: <RawGarden />,
      color: "#fff",
      backgroundColor: "#59611D",
    },
  ];

  const swiperConfig = {
    modules: [Navigation, Pagination, FreeMode],
    className: styles.brandSlider,
    touchEventsTarget: "wrapper",
    breakpoints: {
      1024: {
        slidesPerView: 5,
        slidesPerGroup: 5,
        spaceBetween: 16,
      },
      768: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 14,
      },
      550: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 10,
      },
      0: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 10,
      },
    },
    navigation: {
      prevEl: `#brand-prev`,
      nextEl: `#brand-next`,
      clickable: true,
    },
  };

  return (
    <div className={`${styles.container} brands-slider`}>
      <div className={styles.header}>
        <div className={styles.header__content}>
          <div className={styles.icon}>
            <Brands />
          </div>
          <div className={styles.title}>
            <h2>Brands</h2>
          </div>
        </div>
        <Button
          type="link"
          href="/brands"
          className="sliderLink"
          prefetch={false}
          ariaLabel="View All Brands"
        >
          <span>view all</span>
        </Button>
      </div>

      <Swiper {...swiperConfig}>
        <div className={styles.prev} id={`brand-prev`}>
          <Button type="button" className="slider-arrow" ariaLabel="Previous Slide">
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
        </div>
        <div className={styles.next} id={`brand-next`}>
          <Button type="button" className="slider-arrow" ariaLabel="Next Slide">
            <FontAwesomeIcon icon={faArrowRight} />
          </Button>
        </div>

        {brands.map((brand) => (
          <SwiperSlide key={brand.name} className={styles.slide}>
            <Link
              href={`/brands/${brand.name.replace(/ /g, "-")}`}
              passHref
              style={{ background: brand.backgroundColor, color: brand.color }}
              aria-label={brand.name}
            >
              {brand.logo ? (
                brand.logo
              ) : (
                <div
                  style={{
                    width: "100px",
                    height: "50px",
                  }}
                />
              )}
            </Link>
          </SwiperSlide>
        ))}
        <SwiperSlide className={styles.lastSlide}>
          <Link href="/brands">
          <div className={styles.icon}>
            <Brands />
          </div>
          View All
          </Link>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
