import { createContext, useContext, useState, useEffect, useRef } from 'react'
import { sendGTMEvent } from '@next/third-parties/google';

const ShoppingCartContext = createContext({
  getItemQuantity: null,
  increaseCartQuantity: null,
  decreaseCartQuantity: null,
  removeFromCart: null,
  cartQuantity: null,
  cartItems: [],
  setCartItems: null,
  cartModal: null,
  setCartModal: null,
  removeFromBag: null,
})

export function useShoppingCart() {
  return useContext(ShoppingCartContext)
}

export function CartProvider({ children }) {

  const [cartItems, setCartItems] = useState([])
  const [cartModal, setCartModal] = useState(false)

  // get local storage value
  useEffect(() => {
    const data = window.localStorage.getItem("shopping-cart")
    if (data && data !== undefined) setCartItems(JSON.parse(data))
  }, []);

  // set local storage value
  // initial render to prevent hydration error
  // https://stackoverflow.com/questions/73271093/localstorage-resets-to-empty-on-refresh-in-nextjs
  const initialRender = useRef(true);
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    window.localStorage.setItem("shopping-cart", JSON.stringify(cartItems));
  }, [cartItems]);

  // Cart functions

  const cartQuantity = cartItems.reduce((count, item) => item.count + count, 0)

  function getItemQuantity(productId, priceId) {
    return cartItems.find(item => item.productId === productId && item.priceId === priceId)?.count || 0
  }

  function increaseCartQuantity(productId, priceId) {
    setCartItems(currItems => {
      if (currItems.find(item => item.productId === productId && item.priceId === priceId) == null) {
        return [...currItems, { productId, priceId, count: 1 }]
      }
      else {
        return currItems.map(item => {
          if (item.productId === productId && item.priceId === priceId) {
            return { ...item, count: item.count + 1 }
          }
          else {
            return item
          }
        })
      }
    })
    sendGTMEvent({ event: 'add_to_cart', value: 1, currency: 'USD', items: [{ item_id: productId , item_name: productId}] });
  }

  function decreaseCartQuantity(productId, priceId) {
    setCartItems(currItems => {
      if (currItems.find(item => item.productId === productId && item.priceId === priceId)?.count === 1) {
        return currItems.filter(item => item.productId !== productId || item.priceId !== priceId)
      }
      else {
        return currItems.map(item => {
          if (item.productId === productId && item.priceId === priceId) {
            return { ...item, count: item.count - 1 }
          }
          else {
            return item
          }
        })
      }
    })
    // sendGTMEvent({ event: 'remove_from_cart', value: 1, currency: 'USD', items: [{ item_id: productId , item_name: productId}] });
  }

  function changeItemQuantity(productId, priceId, count) {
    setCartItems(currItems => {
        return currItems.map(item => {
          if (item.productId === productId && item.priceId === priceId) {
            return { ...item, count }
          }
          else {
            return item
          }
        })
    })
  }

  function removeFromCart(productId, priceId) {
    setCartItems(currItems => {
      return currItems.filter(item => item.productId !== productId || item.priceId !== priceId)
    })
    sendGTMEvent({ event: 'remove_from_cart', value: 1, currency: 'USD', items: [{ item_id: productId , item_name: productId}] });
  }

  function removeFromBag(productId) {
    setCartItems(currItems => {
      return currItems.filter(item => item.productId !== productId)
    })
    sendGTMEvent({ event: 'remove_from_cart', value: 1, currency: 'USD', items: [{ item_id: productId , item_name: productId}] });
  }

  return (
    <ShoppingCartContext.Provider
      value={{
        getItemQuantity,
        changeItemQuantity,
        increaseCartQuantity,
        decreaseCartQuantity,
        removeFromCart,
        removeFromBag,
        cartItems,
        cartQuantity, 
        setCartItems, 
        cartModal, 
        setCartModal,
      }}>
      {children}
    </ShoppingCartContext.Provider>
  )
}