import React, { createContext, useContext, useEffect, useState, useCallback, useRef } from "react";
import { fetchAuthSession } from "aws-amplify/auth";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useRouter } from "next/router";

const UserContext = createContext();

export function UserProvider({ children }) {
  const { authStatus, user } = useAuthenticator((context) => [context.authStatus, context.user]);
  const router = useRouter();

  const [userData, setUserData] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);
  const [errorUser, setErrorUser] = useState(null);

  const [orders, setOrders] = useState([]);
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [errorOrders, setErrorOrders] = useState(null);

  // Ref to track if user data is already fetched
  const hasFetchedUserData = useRef(false);

  useEffect(() => {

    const fetchUserData = async () => {
      if (hasFetchedUserData.current) return; // Prevent multiple fetches
      setLoadingUser(true);

      try {
        const { accessToken } = (await fetchAuthSession())?.tokens ?? {};
        const token = accessToken?.toString();
        if (!token) throw new Error("Access token not found");
        const response = await fetch(`/api/jane-user?accessToken=${token}`);
        if (!response.ok) throw new Error(`Failed to fetch user data: ${response.statusText}`);
        const data = await response.json();
        setUserData(data?.data);
        hasFetchedUserData.current = true; // Mark as fetched
      } catch (err) {
        console.error("Error fetching user data:", err);
        setErrorUser(err.message);
      } finally {
        setLoadingUser(false);
      }
    };

    if (user) {
      fetchUserData();
    }
  }, [user]); // Remove dependencies that aren't strictly necessary

  const fetchAllOrders = useCallback(async () => {
    setLoadingOrders(true);
    try {
      const { accessToken } = (await fetchAuthSession())?.tokens ?? {};
      const token = accessToken?.toString();
      const response = await fetch(`/api/jane-orders?accessToken=${token}`);
      if (!response.ok) throw new Error(`Failed to fetch orders: ${response.statusText}`);
      const data = await response.json();
      setOrders(data.orders || []);
    } catch (err) {
      console.error("Error fetching orders:", err);
      setErrorOrders(err.message);
    } finally {
      setLoadingOrders(false);
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        userData,
        loadingUser,
        errorUser,
        orders,
        loadingOrders,
        errorOrders,
        fetchAllOrders,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext);
}