import Link from 'next/link'
import styles from './ProductCard.module.scss'

import Strain from './Strain'
import Kind from './Kind'
import Brand from './Brand'
import FallbackImage from '../fallback-image'
import dynamic from 'next/dynamic';

const AddToCart = dynamic(() => import('./AddToCart'));
const SaleTag = dynamic(() => import('./SaleTag'));
const Dosage = dynamic(() => import('./Dosage'));

export default function ProductCard({ hit, weight, handleClick }) {

  const productLink = '/product/' + hit.product_id + "/" + hit.url_slug

  return (
    <div className={styles.product}
    >

      <ProductImage hit={hit} productLink={productLink} />

      <div className={styles.body}>

        <SaleTag special_amount={hit.special_amount} special_title={hit.special_title} className={styles.saleTag} />
        <Brand brand={hit.brand} />
        <Link className={`${styles.name} text-ellipsis`} href={productLink} onClick={()=>handleClick()}>
        {hit.name.replace(
    /[\[].*?[\]]|Gummies|Sleep Gummies|- Sativa |- Indica|- Hybrid |\b\d+mg\b|\b\d+ml\b|\b\d+thc\b|\b\d+cbd\b|\(.*?(thc|mg|cbd|ml).*?\)|\|.* /gi,
    ""
)}
        </Link>
        <div className={styles.product__info}>
          {(hit.name.toLowerCase().match(/medical (only|patients only)/i) && <span className="medical-only">Medical Only</span>)}
          <Strain category={hit.category} />
          <Kind kind={hit.kind} kind_subtype={hit.kind_subtype} custom_product_subtype={hit.custom_product_subtype} />
        </div>
        <div className={styles.dosages}>
          <Dosage percent_thc={hit.percent_thc} percent_cbd={hit.percent_cbd} dosage={hit.dosage} category={hit.category} />
        </div>

      </div>

      {/* <div className={styles.description}>
        {hit.description}
      </div> */}

      <AddToCart hit={hit} weight={weight} />


    </div>
  )
}

function ProductImage({ hit, productLink }) {
  const photos = hit.has_photos ? hit.photos : hit.product_photos

  return (
    <Link href={productLink} className={styles.image_container}>
      <FallbackImage
        src={photos[0]?.urls?.original || null}
        category={hit.kind}
        root_subtype={hit.root_subtype}
        lineage={hit.category}
        alt={hit.name}
        fill
        sizes="(max-width: 768px) 200px,
                    250px"
      />
    </Link>
  )
}
