
export async function getJaneSpecials(store, type) {
  if (!store) {
    console.log("No store value");
    return;
  }
  try {
    const response = await fetch(`/api/jane-specials?store=${store}&type=${type}`);
    if (!response.ok) {
      throw new Error(`Failed to fetch specials: ${response.status}`);
    }
    const data = await response.json();
    return data.specials;
  } catch (error) {
    console.error("Error fetching specials:", error);
  }
}

export async function getAllJaneSpecials(store) {
  if (!store) {
    console.log("No store value");
    return;
  }

  try {
    // Make fetch requests for both product and bundle types
    const [productResponse, bundleResponse] = await Promise.all([
      fetch(`/api/jane-specials?store=${store}&type=product`),
      fetch(`/api/jane-specials?store=${store}&type=bundle`),
    ]);

    // Check for response errors
    if (!productResponse.ok || !bundleResponse.ok) {
      throw new Error(
        `Failed to fetch specials: ${
          !productResponse.ok ? `Product - ${productResponse.status}` : ""
        } ${!bundleResponse.ok ? `Bundle - ${bundleResponse.status}` : ""}`
      );
    }

    // Parse the JSON responses
    const productData = await productResponse.json();
    const bundleData = await bundleResponse.json();

    // Combine the specials from both responses
    const allSpecials = [
      ...(productData.specials || []),
      ...(bundleData.specials || []),
    ];

    return allSpecials;
  } catch (error) {
    console.error("Error fetching specials:", error);
    return [];
  }
}
